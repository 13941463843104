
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReceitaService,TipoDocumentoService, SituacaoReceitaParcelaService, CentroCustoService, ContaGerencialService, ParametroFinanceiroService, ContaCorrenteService} from "@/core/services/financeiro";
import { Receita, ReceitaParcela, ReceitaClassificacao, Encargo, ParametroGatway, ContaCorrente,} from "@/core/models/financeiro";
import { TipoReceitaService } from "../../../core/services/financeiro/TipoReceitaService";
import { EmpreendimentoService,PessoaService} from "../../../core/services/cadastros";
import { EmpresaService } from "../../../core/services/compras/EmpresaService";
import { Pessoa } from "../../../core/models/cadastros";
import { SituacaoColor } from '@/assets/scripts/helper';
import * as jsonpatch from 'fast-json-patch';
import { TipoOperacaoFinanceiraService } from "@/core/services/contratos";
  
@Component
export default class CadastroReceita extends Vue {
  @Prop() public item!: Receita;
  @Prop() public value!: string;
 
  service = new ReceitaService();
  classificacao = new ReceitaClassificacao();
  parcela = new ReceitaParcela();
   
  tipoDocumentos = [];
  tipoReceitas = [];
  situacaoParcelas:any = [];
  centroCustos:any = [];
  contaGerenciais:any = [];
  empresas = [];
  empreendimentos = [];
  pessoas = [];
  tiposOperacaoContrato = [];
  listaContaCorrentes = [];
  parcelas:any = [];

  dialogCadastroPessoa = false;
  overlay = false;
  pessoa = new Pessoa();
 
  qtdeParcelas = 0;
  valorParcela = 0;
  itemIndex: number = -1;

  parametroGatway = new ParametroGatway()
  listaGateway = [];
 
  tabActive = 0;
  valid = true;
  dialog = false;
  dialogParcela = false;
  loading = false;
 
  $refs!: {
    formReceita: HTMLFormElement;
    formClassificacao: HTMLFormElement;
    formParcela: HTMLFormElement;
  };

  parcelaIndex = -1;
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  headersParcela = [
    { text: "", value: "actions", sortable: false },
    { text: "Parcela", value: "parcela" },
    { text: "Valor", value: "valor" },
    { text: "Linha digitável", value: "linhaDigitavelBoleto" },
    { text: "Vencimento", value: "dataVencimento" },
    { text: "Situação", value: "situacaoId" },
  ];

  headersClassificacao = [
    { text: "", value: "actions", sortable: false },
    { text: "Centro custo", value: "centroCustoId" },
    { text: "Conta gerencial", value: "contaGerencialId" },
    { text: "Valor", value: "valor" },
    { text: "Percentual", value: "percentual"},
  ];

  @Watch("item", {deep:true})
  ObservadorItem(){
    this.observer = jsonpatch.observe(this.item);
  };

  @Watch("item")
  Item() {
    if (this.$refs.formReceita) {
      this.$refs.formReceita.resetValidation();
    }
  }

  @Watch("classificacao")
  Classificacao() {
    if (this.$refs.formClassificacao) {
      this.$refs.formClassificacao.resetValidation();
    }
  }

  @Watch("parcela") 
  Parcela() {
    if (this.$refs.formParcela) {
      this.$refs.formParcela.resetValidation();
    }
  }

  observer! : jsonpatch.Observer<Receita>; 

  @Watch("value") 
  Value() {
    this.dialog = this.value ? true : false; 
    if (this.dialog){
      this.observer = jsonpatch.observe(this.item);
    } else {
      jsonpatch.unobserve(this.item, this.observer);
    }
  }
 
  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }else{
      if(this.item.id> 0)
      this.ObterParametroPorId();
    } 
  } 

  Validacoes(){
    if(!this.item.dataEmissao || !this.item.tipoId || !this.item.tipoDocumentoId || !this.item.valor || !this.item.clienteId || !this.item.empresaId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tabActive = 0;
      return true;
    }

    if(!this.item.tipoOperacaoId){
      this.$swal("Aviso", "Campos obrigatórios não preenchidos.", "warning");
      this.tabActive = 1;
      return true;
    }

    if(this.item.parcelas.length == 0){
      this.$swal("Aviso", "É necessário adicionar as parcelas.", "warning");
      this.tabActive = 2;
      return true;
    }
  }
 
  Salvar() { 
    this.$refs.formReceita.validate();     
     if (!this.Validacoes()) {

      this.item.parcelas.forEach(x => {
        if(x.situacaoId == 1){
          x.tipoGatewayId = this.item.tipoGatewayId; 
          x.encargoFinanceiro = this.parametroGatway.encargoFinanceiro;
          x.tipoOperacaoId = this.item.tipoOperacaoId; 
          x.contaCorrenteId = this.item.contaCorrenteId}
     })

      this.overlay = true;
      let pacthModel = jsonpatch.generate(this.observer);

      (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) :  this.service.Salvar(this.item)).then(
        (res) => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        }, 
         (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }).finally(() => this.overlay = false);
     }
  }

  Atualizar(){
    this.service.ObterPorId(this.item.id, "Parcelas.Baixas, Classificacoes").then((res) => {
      this.item = res.data;
      jsonpatch.unobserve(this.item, this.observer);
    }); 
  }

  Close() {
    this.dialog = false;
    this.ResetEncargos();
    this.ResetAtributos();
  }

  CadastrarPessoa(){
    this.pessoa = new Pessoa();
    this.dialogCadastroPessoa = true;
  }
  
  GerarParcelas() {     
    this.parcelas = [...this.item!.parcelas];
    
    if (this.item.parcelas.some(parcela => parcela.situacaoId !== 1)) 
      return this.$swal("Aviso", "Não é possível editar uma parcela paga ou cancelada.", "warning");
    
    this.item.parcelas = [];
    const dataInicial = new Date(Date.parse(`${this.parcela.dataVencimento} 00:00:00`));

    for (let i = 0; i < this.qtdeParcelas; i++) {
        const parcelaAntiga = this.parcelas[i] || {};

        const dataCalculada = new Date(dataInicial);
        dataCalculada.setMonth(dataInicial.getMonth() + i);

        const novaParcela:any = {
          parcela: i + 1,
          valor: this.valorParcela,
          situacaoId: 1,
          dataVencimento: dataCalculada.yyyymmdd(),
          linhaDigitavelBoleto: this.parcela.linhaDigitavelBoleto,
          ultimaDataPagamento: null,
          ultimaFormaPagamento: null,
          id: parcelaAntiga.id || 0,
          receita: parcelaAntiga.receita,
          receitaId: parcelaAntiga.receitaId || 0,
          parcelaStr: parcelaAntiga.parcelaStr || '',
          saldo: parcelaAntiga.saldo || 0,
          valorAPagarComJurosEMulta: parcelaAntiga.valorAPagarComJurosEMulta || 0,
          valorComTaxaBoleto: parcelaAntiga.valorComTaxaBoleto || 0,
          tipoServicoId: null,
          ultimaTransacao:null,
          valorPago:0,
          situacao:null,
          valoresAdicionais: parcelaAntiga.valoresAdicionais,
      };

        this.item!.parcelas.push(novaParcela);
        this.parcela.linhaDigitavelBoleto = "";
    }

    // Resetar a parcela e outros parâmetros
    this.parcela = new ReceitaParcela();
    this.parcelaIndex = -1;
    this.valorParcela = 0; 
    this.qtdeParcelas = 0;
  }


  EditarParcela(item) {
    this.parcela = item;
    this.parcela.dataVencimento = new Date(item.dataVencimento).yyyymmdd();
    this.dialogParcela = true;
  }

  Confirmar(){
    // Obtenha o índice da parcela editada na lista item.parcelas
    let indiceParcelaEditada = this.item.parcelas.indexOf(this.parcela);
    let valorParcelaEditada = this.parcela.valor;

    // Calcule o valor total das parcelas anteriores à parcela editada
    let valorParcelasAnteriores = 0;
    for (let i = 0; i < indiceParcelaEditada; i++) {
      valorParcelasAnteriores += this.item.parcelas[i].valor;
    }

    // Calcule o saldo restante após a edição da parcela
    let saldoRestante = this.item.valor - valorParcelasAnteriores - valorParcelaEditada;
    
    // Atualize as parcelas posteriores à parcela editada
    for (let i = indiceParcelaEditada + 1; i < this.item.parcelas.length; i++) {
      let parcela = this.item.parcelas[i]; 
      
      let valorFormatado = Number((saldoRestante > 0 ? saldoRestante : 0) / (this.item.parcelas.length - (indiceParcelaEditada + 1))).toFixed(2);
      parcela.valor =  Number(valorFormatado);
    }

    let valortotal = this.item.parcelas.reduce((total, parcela) => total + parcela.valor, 0).toFixed(2);
    this.item.valor = Number(valortotal);
    this.parcela = new ReceitaParcela();
    this.dialogParcela = false;
  }
     
  ExcluirParcela(item) {
      this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => { 
        if(item.id > 0)
         this.service.ExcluirParcela(item.id).then();

        const index = this.item.parcelas!.indexOf(item);
        this.item.parcelas!.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){}
    })
  } 
  
  valorTotal: number = 0;

 
  @Watch('itemIndex')
  @Watch('item.valor')
  @Watch('item.classificacoes')
  updateValorTotal() {
    const valorClassificacoes = this.item.classificacoes.reduce((total, classificacao) => total + classificacao.valor, 0);
    this.valorTotal = this.item.valor - valorClassificacoes;
  }

  AdicionarClassificacao() {
    if (this.$refs.formClassificacao.validate()) {
      let valorRestante = Number(this.valorTotal);

      if (this.itemIndex > -1) {
        const currentClassification = this.item.classificacoes[this.itemIndex];
        valorRestante += currentClassification.valor;
      }

      if (this.classificacao.valor > valorRestante) {
        return this.$swal("Aviso", "O valor não pode ser maior que o valor a ser dividido", "warning");
      }      

      if (this.itemIndex > -1) {
        Object.assign(this.item.classificacoes[this.itemIndex], this.classificacao);
      } else {
        this.item.classificacoes.push(this.classificacao);
      }

      this.classificacao = new ReceitaClassificacao();
      this.itemIndex = -1;
    }
  }

  EditarClassificacao(item) {
    this.itemIndex = this.item.classificacoes.indexOf(item);
    this.classificacao = Object.assign({}, item);
  }

  ExcluirClassificao(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
          item.excluido = true;
          const index = this.item.classificacoes!.indexOf(item);
          delete this.item.classificacoes![index]
          this.dialog = false;
          this.dialog = true;
          return true;
        }else{
          const index = this.item.classificacoes!.indexOf(item);
          this.item.classificacoes!.splice(index, 1);
          return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }

  SituacaoColor(item){
    return SituacaoColor(item)
  } 
  
  ObterParametroGatway(item){
    if(item){
      this.parametroGatway.encargoFinanceiro = item.encargoFinanceiro;
      this.item.tipoGatewayId = item.tipoId;
    }else{
      this.parametroGatway.encargoFinanceiro = new Encargo();
      this.item.tipoGatewayId = 0;
    } 
  }
  
  ObterParametroPorId(){
    new ParametroFinanceiroService().ObterParametroEmpresaPorId(this.item.empresaId, 'Gatways.Tipo').then(
      res => { 
        this.listaGateway = res.data.gatways;
      },
      err => {
        this.ResetEncargos();
      }
    )
  }

  ObterEncargosContaCorrente(){
    new ContaCorrenteService().ObterPorId(this.item.contaCorrenteId).then(
      res => {
       this.parametroGatway.encargoFinanceiro = res.data.encargoFinanceiro;
      }
    )
  }

  Dividir = (x: any, b: any) => parseFloat(x) / parseFloat(b);

  @Watch("dialog")
  ObterEncargosPrimeiraParcela(dialog){
    if(dialog == null)
      return;
    
    if(dialog == true){
      this.parametroGatway.encargoFinanceiro = this.item.parcelas.length > 0 ? this.item.parcelas[0].encargoFinanceiro : new Encargo();
    }
  }

  ResetEncargos(){
    this.parametroGatway = new ParametroGatway();
  }

  ResetAtributos(){
    this.qtdeParcelas = 0;
    this.valorParcela = 0;
    this.tabActive = 0;
  }

  AtualizarPessoas(){
    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      (res) => {this.pessoas = res.data.items})
  }
   
  ObterEmpreendimentosPorEmpresa(){
     let filter = {empresaId:this.item.empresaId};
      new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',['Id,Nome, EmpresaId'], filter, '' , '', '').then(
        (res) => {this.empreendimentos = res.data.items});
   }

  @Watch("classificacao.valor")
  CalcularPercentualValor(valor){
    if(valor > 0){
      let percentual =  valor * 100 / Number(this.item.valor); 
      this.classificacao.percentual = Number(percentual.toFixed(2));
    }
  }
 
  CalcularValorPercentual(valor){
    let valorClassificado = (this.item.valor * valor) / 100; 
    this.classificacao.valor = Number(valorClassificado.toFixed(2));
  }

  mounted() {
  this.AtualizarPessoas(); 

  const contaCorrenteService = new ContaCorrenteService();
    contaCorrenteService.ListarTudo().then(
      (res) => {
        this.listaContaCorrentes = res.data.items;
      }
    )

   const tipoOperacaoContrato = new TipoOperacaoFinanceiraService();
    tipoOperacaoContrato.ListarTudo().then( 
      (res) => {
        this.tiposOperacaoContrato = res.data.items;
      }
    )

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      res => this.pessoas = res.data.items
    )    

    new TipoDocumentoService().ListarTudo().then(
      res => this.tipoDocumentos = res.data.items
    );

    new TipoReceitaService().ListarTudo().then(
      res => this.tipoReceitas = res.data.items
    );

    new SituacaoReceitaParcelaService().ListarTudo().then(
      res => this.situacaoParcelas = res.data.items
    );
 
    new CentroCustoService().ListarTudo().then(
      res => this.centroCustos = res.data.items
    );

    new ContaGerencialService().Listagem().then(
      res => this.contaGerenciais = res.data
    );

    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
      res => this.empresas = res.data.items
    );   
  }
}
