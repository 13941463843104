
import { Assinante, Permissao, Usuario } from "@/core/models/cadastros";
import { PageBase } from "@/core/models/shared";
import { AssinanteService, UsuarioService } from "@/core/services/cadastros";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { defineComponent, computed } from 'vue';

@Component
export default class MasterPage extends PageBase {
  @Prop() private titulo!: string;
  @Prop() private subTitulo!: string;


  item = new Usuario();
  service = new UsuarioService();
  assinante = new Assinante();
   
  dialogCadastro: boolean = false; 
  dialogCadastroAssinante:boolean = false;
  drawer: boolean = false;
   isFullScreen: boolean = false

mini: boolean = true

      DashboardDrawer= false
      NegociosDrawer= false
      VendasDrawer= false
      FinanceiroDrawer= false
      SuprimentosDrawer= false
      ProjetosDrawer= false
      AguasDrawer= false
      AdmDrawer= false
      LayoutDevDrawer= false
      isSpinning = false

      toggleSpin(){
        this.isSpinning = !this.isSpinning

         setTimeout(() => {
        this.isSpinning = false;
      }, 500); 
    }
      


     
    resetDrawers() {
      this.DashboardDrawer = false;
      this.NegociosDrawer = false;
      this.VendasDrawer = false;
      this.FinanceiroDrawer = false;
      this.SuprimentosDrawer = false;
      this.ProjetosDrawer = false;
      this.AguasDrawer = false;
      this.AdmDrawer = false;
      this.LayoutDevDrawer = false;
    }

    
    resetAndToggle(drawer: string) {
      const currentValue = this[drawer];
      this.resetDrawers();
      this[drawer] = !currentValue;
    }



      handleClick(drawer: string) {
      setTimeout(() => {
        this.resetAndToggle(drawer);
      }, 0);
    }
  





 toggleFull() {
      let elem = document.documentElement;

      if (!document.fullscreenElement) {
        elem.requestFullscreen()
          .then(() => {
            this.isFullScreen = true;
          })
          .catch((err) => {
            alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
          });
      } else {
        document.exitFullscreen()
          .then(() => {
            this.isFullScreen = false;
          });
      }
    }
  
  Assinante(){   
    let serviceAssinante = new AssinanteService();
      serviceAssinante.ObterPorId(this.app.assinanteId, 'Dominios, Contatos').then(
        res => {
        this.assinante = new Assinante(res.data);
        //this.assinante.responsavel.dataNascimento = new Date(`${res.data.responsavel.dataNascimento}`).yyyymmdd()
        this.dialogCadastroAssinante = true
    }) 
  }

  MeusDados(){
    this.service.getUsuario(this.app.usuarioId).then(
      res => {
        this.item = res.data;
        this.item.foto = this.app.usuarioFoto;
        this.dialogCadastro = true;
      })
  }
  
  MudarTema(){
    const usuarioService = new UsuarioService();
      usuarioService.MudarTema(this.app.usuarioId).then(
        res => super.MudarTema(res.data),
        err => this.$swal('Aviso', err.message, 'error')
      );
  };

  Logout() {
    localStorage.clear();
    this.$vuetify.theme.dark = false;
    this.$router.push({ name: "login" });
  }

  permissao =   Permissao; 

  opcoesMenu = [
    { title: 'Meu espaço', icon: 'mdi-home', linkPath:'/home', condicao:true,lista:[] },
    { title: 'Dashboards', icon: 'mdi-chart-bar',linkPath: '/relatorio/financeiro/kpi', condicao:this.app.permissoes.some(x => (x == this.permissao.Modulos_Modulo_KPI) || (x == this.permissao.master)) || this.app.permissoes.some(x => (x == this.permissao.Modulos_Modulo_Contrato) || (x == this.permissao.master)) || this.app.permissoes.some(x => (x == this.permissao.Modulos_Modulo_Financeiro) || (x == this.permissao.master)), lista:[]},
    { title: 'Negócios', icon: 'mdi-grid',linkPath: '', condicao: this.app.permissoes.some(x => (x == this.permissao.Modulos_Modulo_Negocio) || (x == this.permissao.master)), 
      lista:[
        {title: 'Empresas',linkPath: '/gerencial/empresa'}, {title: 'Pessoas',linkPath: '/cadastro/pessoa'},{title: 'Empreendimento',linkPath: '/cadastro/empreendimento'}, {title: 'Cartórios',linkPath: '/cadastro/cartorio'}
      ]
    },
  ]

  mounted() {
    this.$vuetify.theme.dark = this.app.temaEscuro;
  }
}
