
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DimensaoLote, Empreendimento, EnumTipoEmpreendimento, EnumTipoUnidade, Grupo, ProprietarioEmpreendimento, ProprietarioUnidade, Unidade } from "@/core/models/cadastros";
import { EmpreendimentoService, PessoaService, SituacaoUnidadeService, TipoUnidadeService } from "@/core/services/cadastros";
import { TipoEmpreendimentoService } from "@/core/services/empreendimento/index";
import { CartorioService } from '@/core/services/imobiliaria'; 
import {  Foto, Imovel, InfraestruturaImovel, Lote } from '@/core/models/imobiliaria';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import * as jsonpatch from 'fast-json-patch';
import { ArquivoService } from "@/core/services/geral/ArquivoService";
import { SituacaoColorContrato, TratarErroApi } from "@/assets/scripts/helper";
import { PlanoPagamentoService } from "@/core/services/contratos";
import { PlanoPagamentoModelo } from "@/core/models/contratos";
import { Documento } from "@/core/models/geral";

@Component
export default class CadastroEmpreendimento extends Vue { 
  
  
  @Prop() public empreendimento!: Empreendimento;
  @Prop() public value!: string;
  
  item = new Empreendimento();
  observer! : jsonpatch.Observer<Empreendimento>;
  service = new EmpreendimentoService();

  planoPagamento = new PlanoPagamentoModelo()

  SelecionarPlano(id){
    new PlanoPagamentoService().ObterPorId
    (id,"IntervaloReajuste,TipoIndice,TipoAmortizacao,TipoMesReajuste,TipoAnoInicioReajuste,TipoValorTotal,TipoOperacao,TipoGateway,ContaCorrente").
    then(
      (res)  => {
        this.planoPagamento = res.data
      }
    )
 
  }



  empreendimentoDocumento = new Empreendimento();
  informacaoUnidade = new Unidade();
  //dimensao = new DimensaoLote();
  loteEspelho: Lote = new Lote();
  unidade = new Unidade();
  incluirUnidade = new Grupo();
  proprietario = new ProprietarioEmpreendimento();

  overlayDocumentos = false;
  imgUrl:any = null;
  dialogLadosAdicionais = false;
  dialogUnidadeInformacao = false;
  overlayEmpreendimento = false;   
  dialogLotesPadrao = false;
  dialogUnidade = false;
  mapaDialog = false;
  overlay = false;
  valid = true; 
  dialog = false;
  arquivo:any = null;
  dialogGrupos= false;
  dialogPlano = false;
  xmlString:string = "";
  
  tabAtiva = 0;
  qtdeUnidade = 0;
  subTabActive = 0;
  qtdeQuadras = 0;
  index = 0;
  itemIndex = -1;

  listaMunicipio = [];
  listaEstado = [];
  listaTipoEmpreendimento = [];
  listaEmpresa = [];
  listaCartorio = []; 
  listaSituacao:any = [];
  listaTipos = [];
  pessoas:any =[];
  grupos:any = [];
  planosPagamento = [];
  inputDocumento:any = null;
  imagemSelecionada:any = null;
  model:any = null;
    transparent:string = 'rgba(255, 255, 255, 0)';
  

 
  
  get totalAreaPublica(): number {
    return Number(this.item.infraestrutura.areaPrefeitura) + Number(this.item.infraestrutura.areaRua) + this.espacoLivrePublico;
  }

  get espacoLivrePublico(): number{
    return Number(this.item.infraestrutura.areaVerde) + Number(this.item.infraestrutura.areaOutras);
  }

  get areaTotalLoteada(): number{
    return Number(this.item.infraestrutura.areaUnidade) + this.totalAreaPublica + Number(this.item.infraestrutura.areaReservadoProprietario)
  }


  documento:any = new Documento();

  async AdicionarArquivo() {
    if (!this.inputDocumento) {
      this.$swal("Aviso", "É necessário selecionar uma foto.", "warning");
      return;
    } 
    let dados = await new ArquivoService().Ler(this.inputDocumento);
    this.documento.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
    this.documento.dataEmissao = new Date().yyyymmdd();
    this.documento.dados = dados.replace(/^[^,]*,/, "");
    this.documento.nome = this.documento.nome != "" ? this.documento.nome : this.inputDocumento.name;
    this.documento.tamanho = this.inputDocumento.size;
    this.documento.tipo = this.inputDocumento.type;
    this.item.fotos.push(this.documento);   
    this.documento = new Documento();
    this.inputDocumento = null;
 
  }
   toggleImage(index: number) {
      this.model = index;
      this.imagemSelecionada = this.empreendimento.fotos[index]; // Define a foto selecionada
    }
  ExcluirDocumentoDois(foto) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(foto.id > 0){
         foto.excluido = true;
         const index = this.empreendimento.fotos!.indexOf(foto);
        //  this.empreendimento.fotos!.splice(index, 1);
        delete this.empreendimento.fotos![index];
         this.dialog = false;
         this.dialog = true;
         this.SalvarImagem();
         return true; 
        }else{
        const index = this.empreendimento.fotos!.indexOf(foto);
        this.empreendimento.fotos!.splice(index, 1);
        return this.$swal("Aviso", "Registro excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
        this.SalvarDoc(this.empreendimento.fotos!.indexOf(foto));
      }
    })
  }

  SalvarDoc(index){
    this.observer = jsonpatch.observe(this.empreendimento.fotos[index]);
    let pacthModel = jsonpatch.generate(this.observer);

    this.service.Patch(pacthModel, this.empreendimento.id).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
        this.$emit("salvou");
        jsonpatch.unobserve(this.empreendimento, this.observer);
      },
      (err) => {
        TratarErroApi(err)
     });
  } 

    BaixarArquivo(index){
       this.imagemSelecionada = this.empreendimento.fotos[index];
    new ArquivoService().Download(this.imagemSelecionada);
  }

  CompartilharArquivo(index){
     this.imagemSelecionada = this.empreendimento.fotos[index];
    new ArquivoService().Compartilhar(this.imagemSelecionada);
  }
//  async AdicionarArquivo() {
//     if (!this.inputDocumento) {
//       this.$swal("Aviso", "É necessário selecionar um arquivo.", "warning");
//       return;
//     } 
//     let dados = await new ArquivoService().Ler(this.inputDocumento);
//     this.documento.usuarioId = JSON.parse(localStorage.sessionApp.split(".")).dados.usuarioId;
//     this.documento.dataEmissao = new Date().yyyymmdd();
//     this.documento.dados = dados.replace(/^[^,]*,/, "");
//     this.documento.nome = this.documento.nome != "" ? this.documento.nome : this.inputDocumento.name;
//     this.documento.tamanho = this.inputDocumento.size;
//     this.documento.tipo = this.inputDocumento.type;
//     this.contratoDocumento.documentos.push(this.documento);   
//     this.documento = new DocumentoContrato();
//     this.inputDocumento = null;
//     this.SalvarDoc();
//   }




  //  getPlanoSelecionadoInfo(planoId: number): string {
  //     const planoSelecionado = this.planosPagamento.find(
  //       (plano) => plano.id === planoId
  //     );
  //     if (planoSelecionado) {
  //       return `
  //         Nome: ${planoSelecionado.nome}
  //         Descrição: ${planoSelecionado.descricao}
  //         Valor: R$ ${planoSelecionado.valor}
  //         Parcelas: ${planoSelecionado.parcelas} vezes
  //       `;
  //     }
  //     return 'Plano não encontrado';
  //   }


  headersDocumentos: any[] = [ 
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome", align: "center",sortable: false },
    { text: "Arquivo", value: "dados", align: "center",sortable: false },
    { text: "Data", value: "dataEmissao", align: "center",sortable: false },
  ]; 

   optionsDocumentos = {
    itemsPerPage: 5
  };

  $refs!: {
    formEmpreendimento: HTMLFormElement,
    formProprietario:HTMLFormElement,
    uploader:HTMLFormElement,
    uploaderMapa:HTMLFormElement
  };

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  headersProprietario = [
    { text: "", value:"actions", sortable: false },
    { text: "Nome", value: "proprietarioId" },
    { text: "Participação", value: "participacao" },
    { text: "Observação", value: "observacao" },
  ];

  headerLotes = [
    { text: "Nome", value: "nome",align:"center", sortable: false },
    { text: "Disponibilidade",align:"center", value: "Disponibilidade", sortable: false },
    { text: "Frente", value: "Frente",align:"center", sortable: false },
    { text: "Direito", value: "Direito",align:"center", sortable: false },
    { text: "Esquerdo", value: "Esquerdo",align:"center", sortable: false },
    { text: "Fundo", value: "Fundo",align:"center", sortable: false },    
    { text: "Curva", value: "Curva",align:"center", sortable: false },
  ];

  headerImoveis = [
    { text: "Nome", value: "nome",align:"center", sortable: false },
    { text: "Disponibilidade",align:"center", value: "Disponibilidade", sortable: false },
    { text: "Área Imovel", value: "areaImovel",align:"center", sortable: false },
    { text: "Área Terreno", value: "areaTerreno",align:"center", sortable: false },
    { text: "Coe. Aproveitamento", value: "coeficienteAproveitamento",align:"center", sortable: false },
    { text: "Qtde Banheiro", value: "quantidadeBanheiro",align:"center", sortable: false },
    { text: "Qtde Quarto", value: "quantidadeQuarto",align:"center", sortable: false },
    { text: "Qtde Vagas", value: "quantidadeVagas",align:"center", sortable: false },
    { text: "Zona Uso", value: "zonaUso",align:"center", sortable: false },
  ];

  headerJazigos = [
    { text: "Nome", value: "nome",align:"center", sortable: false },
    { text: "Disponibilidade",align:"center", value: "Disponibilidade", sortable: false },
  ];

  listaSituacoes = [
    { nome:"Disponível",color:"#E9C46A", id:1},
    { nome:"Indisponível", color: "#264653", id:2 },
    { nome:"Vendido",color:"#2A9D8F", id:3 },
    { nome:"Reservado",color:"#E76F51", id:4 },
    { nome:"Invadido",color:"#7b2cbf", id:5 },
    { nome:"Penhorado",color:"#219ebc", id:6 },
  ];
 
  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false; 
      if (this.dialog){
         this.item = this.empreendimento;
    
        if(this.item.id > 0){
          this.ObterGrupos();
          this.ObterFoto();
          this.observer = jsonpatch.observe(this.item);
        }
      } else {
        jsonpatch.unobserve(this.item, this.observer);
      }
  }

  @Watch("item", {deep:true})
  ObservadorItem(){
    this.observer = jsonpatch.observe(this.item);
  }; 

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    } 
  } 
 
  @Watch('item') 
  Item(){
    if (this.$refs.formEmpreendimento) { 
      this.$refs.formEmpreendimento.resetValidation();
    }
  }
  
  @Watch('proprietario') 
  Proprietario(){
    if (this.$refs.formProprietario) {
      this.$refs.formProprietario.resetValidation();
    }
  }

  UploaderMapa() {
    window.addEventListener('focus', () => {
    }, { once: true, passive: true })

    this.$refs.uploaderMapa.click();
  }; 

  EnviarArquivoMapa(event:Event) {
   const fileInput:any = event.target as HTMLInputElement;
    const file = fileInput.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const kmlContent = reader.result as string;
        const xmlDoc = new DOMParser().parseFromString(kmlContent, 'text/xml');
        this.xmlString = new XMLSerializer().serializeToString(xmlDoc);
      };
      reader.readAsText(file);
    }
  }

  GerarUnidades() {
    if (this.qtdeUnidade <= 0){
      return this.$swal('Atenção!', 'A quantidade não pode ser menor ou igual a 0.', 'warning');
    }
    
    if (this.qtdeUnidade > 260) 
      return this.$swal('Atenção!', 'Não é permitido exceder o limite de 260 unidades.', 'warning');

    for (let controle = 1; controle <= this.qtdeUnidade; controle++) {
      const unidade = new Unidade();
        unidade.legalizacao = { ...this.item.legalizacao };
        unidade.grupoId = this.incluirUnidade.id;
        unidade.planoPagamento = undefined;

      this.item.proprietarios.forEach(f => {
        const propUnd = new ProprietarioUnidade();
          propUnd.proprietarioId = f.proprietarioId;
          propUnd.participacao = f.participacao;
          propUnd.observacao = f.observacao;
        
        if (!unidade.proprietarios.some(s => s.proprietarioId == f.proprietarioId)) {
          unidade.proprietarios.push(propUnd);
        }
      });

      unidade.nome = `${controle < 10 ? `0${controle}` : controle}`;
      unidade.endereco = { ...this.item.endereco };
      unidade.lote = undefined;
      unidade.imovel = undefined;
      unidade.jazigo = undefined;

      if(this.item.tipoId == EnumTipoEmpreendimento.Loteamento)
      {
        this.loteEspelho.dimensao = { ...this.item.infraestrutura.dimensaoLotePadrao };
        this.loteEspelho.ladosAdicionais = this.item.ladosAdicionaisPadroes;
        this.loteEspelho.ladosAdicionais.forEach((x:any) => { x.id = 0, x.empreendimentoId = null });
        unidade.tipoId = EnumTipoUnidade.Lote;
        unidade.lote = Object.assign({}, this.loteEspelho);
      }
        else if(this.item.tipoId == EnumTipoEmpreendimento.Predio || this.item.tipoId == EnumTipoEmpreendimento.Condominio)
      {
        unidade.tipoId = EnumTipoUnidade.Imovel;
        unidade.imovel = new Imovel();
        unidade.imovel.infraestrutura = new InfraestruturaImovel();
       }
        
      this.incluirUnidade.unidades.push(unidade);
    }

    if (this.item.id > 0) {
      this.SalvarGrupo(this.incluirUnidade);
    }
    this.qtdeUnidade = 0;
    this.dialogUnidade = false;
  }

  fecharGerarUnidades(){
    this.qtdeUnidade = 0;
    this.dialogUnidade = false;
  }

  AdicionarProprietario() {
    if (this.$refs.formProprietario.validate()) {
      const proprietarioId = this.proprietario.proprietarioId;
      const proprietarioExistente = this.item.proprietarios.find((x, index) => x.proprietarioId === proprietarioId && index !== this.itemIndex);
      
      if (proprietarioExistente) 
        return this.$swal("Aviso", "Esta pessoa já foi adicionada na lista.", "warning");
      
      if (this.itemIndex > -1) {
        Object.assign(this.item.proprietarios[this.itemIndex], this.proprietario);
      } else {
        this.item.proprietarios.push(this.proprietario);
      }
      
      this.proprietario = new ProprietarioEmpreendimento();
      this.itemIndex = -1;
    }
  }

  

  ExcluirProprietario(item: ProprietarioEmpreendimento) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => {
        if(item.id > 0){
         item.excluido = true;
         const index = this.item.proprietarios.indexOf(item);
         delete this.item.proprietarios[index]
         this.dialog = false;
         this.dialog = true;
         return true;
        }else{
        const index = this.item.proprietarios.indexOf(item);
        this.item.proprietarios.splice(index, 1);
        return this.$swal("Aviso", "Item excluído com sucesso!", "success");
        }
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if(result.value){
      }
    })
  }   
 
  EditarProprietario(item){
    this.itemIndex = this.item.proprietarios.indexOf(item);
    this.proprietario = Object.assign({}, item);
  }

  

   SalvarImagem() { 
    this.$refs.formEmpreendimento.validate();
      if (!this.Validacoes()) {
        if(this.item.id == 0){
          this.item.grupos = this.grupos;
          this.item.documentos = this.empreendimentoDocumento.documentos;
        } 
       
        if(!this.item.kml || this.xmlString){
          this.item.kml = this.xmlString
        }
        this.item.tipoId = 1
    
        let modelo = this.item;
        let pacthModel = jsonpatch.generate(this.observer);


        (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(modelo)).then(
         (res) => {
          this.Atualizar();    
         },        
        (err) => TratarErroApi(err)
        ).finally(() => {this.overlayEmpreendimento = false})
    }  
  } 
     
  Salvar() { 
    this.$refs.formEmpreendimento.validate();
      if (!this.Validacoes()) {
        if(this.item.id == 0){
          this.item.grupos = this.grupos;
          this.item.documentos = this.empreendimentoDocumento.documentos;
        } 
       
        if(!this.item.kml || this.xmlString){
          this.item.kml = this.xmlString
        }
        this.item.tipoId = 1
    
        let modelo = this.item;
        let pacthModel = jsonpatch.generate(this.observer);


        (this.item.id > 0 ? this.service.Patch(pacthModel, this.item.id) : this.service.Salvar(modelo)).then(
         (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");            
          this.$emit("salvou"); 
          console.log(modelo)
                  this.Atualizar();
                  this.Close();
         },        
        (err) => TratarErroApi(err)
        ).finally(() => {this.overlayEmpreendimento = false})
    }  
  } 
 
  GetDocumentos(atualizar = false){
    if(this.item.id > 0){
      if(this.empreendimentoDocumento.documentos.length == 0 || atualizar){
        this.overlayDocumentos = true;

        this.service.ObterPorId(this.item.id, "Documentos").then((res) => {
          this.empreendimentoDocumento = res.data;
          this.overlayDocumentos = false;
        }, 
        (err) => TratarErroApi(err));  
      }
    } 
  } 
 
  Close() {
    this.dialog = false;
    this.Reset();
  }
 
  /* Grupos */
  CriarGrupos(){
    let novosGrupos = this.grupos; 
    this.service.SalvarGrupos(novosGrupos).then(
      (res) => {
        this.$emit("salvou"); 
        this.Atualizar();
      },  
      (err) => TratarErroApi(err)
    )
  }

  SalvarUnidadesPorGrupo(item){

    item.unidades.forEach(
      x => { x.lote.dimensao.curva = this.item.infraestrutura.dimensaoLotePadrao.curva, 
        x.lote.dimensao.frente = this.item.infraestrutura.dimensaoLotePadrao.frente,
        x.lote.dimensao.fundo = this.item.infraestrutura.dimensaoLotePadrao.fundo,
        x.lote.dimensao.ladoDireito = this.item.infraestrutura.dimensaoLotePadrao.ladoDireito,
        x.lote.dimensao.ladoEsquerdo = this.item.infraestrutura.dimensaoLotePadrao.ladoEsquerdo}
    )

    let modelo = item;
    this.service.SalvarGrupo(item.id, modelo).then(
      (res) => {
        this.$swal("Aviso","Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");            
        this.$emit("salvou"); 

        if(this.item.id > 0){
          this.Atualizar();
        }  
      },  
      (err) => TratarErroApi(err)
      ); 

    this.dialogLotesPadrao = false;
    //this.dimensao = new DimensaoLote();
  }
  
  SalvarGrupo(item){
    if(this.item.id > 0){
      let modelo = item;
      this.service.SalvarGrupo(item.id, modelo).then(
        (res) => {
            this.Atualizar();
        },  
        (err) => TratarErroApi(err)
        );
     } 
  }

  ExcluirGrupo(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true, 
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm:true,
      preConfirm:() => { 
        const index = this.grupos.indexOf(item);
        this.grupos.splice(index, 1);

        if(item.id > 0)
          this.service.ExcluirGrupo(item.id).then(
            (res) => {
              if (res.status == 200) {
                this.$emit("salvou"); 
                this.Atualizar();
              }
            },
            (err) => TratarErroApi(err)
          );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    })
  }
  
  Atualizar(){   
    if(this.item.id > 0){
      this.service.ObterPorId(this.item.id, "Proprietarios, LadosAdicionaisPadroes, Fotos").then((res) => {
        this.item = res.data;
          this.ObterGrupos();
          jsonpatch.unobserve(this.item, this.observer);
          this.empreendimento = res.data;
          this.model = null;
      },
      (err) => TratarErroApi(err)); 
    }
  } 

  ObterUnidade(unidade, quadra, index){
    if (unidade.id > 0) {
      this.service.ObterUnidade(unidade.id, 'ConfrontantesAdicionais, Imovel, Lote, Lote.Infraestrutura, Lote.LadosAdicionais, Grupo, PlanoPagamento.Intermediaria, PlanoPagamento.Financiamento, PlanoPagamento.Entrada').then(
        res => {
          this.setInformacaoUnidade(res.data, index);
        }
      );
    } else {
      this.setInformacaoUnidade(unidade, index);
    }
  }

  setInformacaoUnidade(informacao, index) {
    this.informacaoUnidade = informacao;
    this.dialogUnidadeInformacao = true;
    this.index = index;
  }

  abrirUnidades(item){
    this.incluirUnidade = item;
    this.dialogUnidade = true;
  } 

  /* Obter Informações */
  ObterGrupos(){
    this.service.ListarGrupo(this.item.id, 'Unidades.Lote, Unidades.Imovel').then(
    (res) => {
      this.grupos = res.data;
    },
    (err) => TratarErroApi(err)
    )
  } 
 
  ObterFoto(){
    this.service.ExibirFoto(this.item.id).then(
      (res) => {
        this.imgUrl = res.data;
      },
      (err) => TratarErroApi(err)
    )
  }

  /* Anexar foto */
  Upload() {    
    window.addEventListener('focus', () => {
    }, { once: true,passive: true })

    this.$refs.uploader.click();
  }; 
   
  async EnviarArquivo(e) {
    let dados = await new ArquivoService().Ler(e.target.files[0]);
    this.item.fotoPrincipal = dados.replace(/^[^,]*,/, "");
    this.imgUrl = dados.replace(/^[^,]*,/, "");
  }

  Validacoes(){
    if(!this.item.empresaId || !this.item.nome ){
      this.$swal("Atenção!", "Campos obrigatórios não preenchidos.", "warning");
      this.tabAtiva = 0; this.subTabActive = 0;
      return true;
    }

     if(!this.item.endereco.cep || !this.item.endereco.logradouro || !this.item.endereco.bairro || !this.item.endereco.estadoId || !this.item.endereco.municipioId){
      this.$swal("Atenção!", "É necessário preencher o endereço completo do Empreendimento.", "warning");
      this.tabAtiva = 0; this.subTabActive = 0;
      return true;
    }

    if(this.item.proprietarios.length == 0){
      this.$swal('Atenção!', 'É necessário adicionar um ou mais proprietarios para este empreendimento.', 'warning');
      this.tabAtiva = 1; this.subTabActive = 0;
      return true;
    } 

    this.overlayEmpreendimento = !this.overlayEmpreendimento;
  }

  Reset(){
    this.grupos = [];
    this.index = 0;
    this.tabAtiva = 0;
    this.subTabActive = 0;
    this.qtdeQuadras = 0;
    this.imgUrl = null;
    this.overlayEmpreendimento = false;
    this.model = null;
  }

  SituacaoColor(item) {
    return SituacaoColorContrato(item);
  }

  get iconeDados(){
    if (!this.item.endereco.cep || !this.item.endereco.logradouro || !this.item.endereco.bairro || !this.item.endereco.estadoId || !this.item.endereco.municipioId ) return 'mdi-lock'
    return 'mdi-lock-open'
  }

  get iconeGrupos(){
    if (!this.item.endereco.cep || !this.item.endereco.logradouro || !this.item.endereco.bairro || !this.item.endereco.estadoId || !this.item.endereco.municipioId  || !this.item.empresaId || !this.item.tipoId || !this.item.nome) return 'mdi-lock'
    return 'mdi-lock-open'
  }
  
  get inconeUnidades(){
    if (!this.item.grupos.length && !this.grupos.length) return 'mdi-lock'
    return 'mdi-lock-open'
  }

  mounted() {  
    this.model = null;
    new PlanoPagamentoService().ListarTudo().then(res => {
      this.planosPagamento = res.data.items;
    })

    new TipoUnidadeService().ListarTudo().then(res => {
      this.listaTipos = res.data.items;
    });

    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(res => {
      this.listaEmpresa = res.data.items;
    })

    new TipoEmpreendimentoService().ListarTudo().then(res => {
      this.listaTipoEmpreendimento = res.data.items.filter(x=>x.id != 4);
    });

    new CartorioService().ListarTudo().then(res => {
      this.listaCartorio = res.data.items;
    });
 
    new SituacaoUnidadeService().ListarTudo().then(res => {
      this.listaSituacao = res.data.items;
    });

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf, isProprietario', '').then(res => {
      this.pessoas = res.data.items.filter(x=>x.isProprietario == true);
    })
  }
}
