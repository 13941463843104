import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/painel/kpi.vue'
import Login from '../views/Login.vue'
Vue.use(VueRouter)
 
const routes = [
  {
    path: '*',
    redirect: '/login'
  }, 
  {
    path: '/',  
    alias: '/financeiro/painel/kpi',  
    name: 'home', 
    component: Home,
    beforeEnter: (to: any, from: any, next: any) => {
   
      if (localStorage.getItem('sessionApp') != null) {
         let exp: any = JSON.parse(atob(localStorage.getItem('sessionApp')!.split('.')[1])).exp;
 
        let expDate = new Date(exp * 1000);
        let today = new Date();
        if (today > expDate) {
          localStorage.clear();
          next('/login');
        } else { 
          next();
        }
      } else {
        next('/login');  
      }
    }
  },   
  {path: '/login',  name: 'login',component: Login},
  
  /*DASHBOARD*/
  //{path: '/relatorio/financeiro/painel/kpi',name: 'financeiroKpi',component: () => import('../views/home.vue')},
   {path: '/relatorio/FluxoCaixa',name: 'FluxoCaixa',component: () => import('../views/painel/FluxoCaixaAntigo.vue')},

  /* NEGOCIOS */ 
  {path: '/negocios/cartorio',name: 'negociosCartorio',component: () => import('@/views/negocios/cartorio/ListaCartorio.vue')}, 
  {path: '/negocios/pessoa',name: 'negociosPessoa',component: () => import('@/views/negocios/pessoa/ListaPessoa.vue')},
  {path: '/negocios/empreendimento',name: 'negociosEmpreendimento',component: () => import('@/views/negocios/empreendimento/ListaEmpreendimento.vue')},
  {path: '/negocios/empresa',name: 'negociosEmpresa',component: () => import('../views/negocios/empresa/ListaEmpresa.vue')},
  {path: '/relatorio/taxaAdm',name: 'relatorioTaxaAdm',component: () => import('@/views/negocios/relatorios/CfgTaxaAdm.vue')},
  {path: '/relatorio/taxaAdm/visualizacao',name: 'RelTaxaAdm',component: () => import('@/views/negocios/relatorios/RelTaxaAdm.vue')},
  {path: '/relatorio/Recebiveis',name: 'relatorioRecebiveis',component: () => import('@/views/negocios/relatorios/CfgRecebiveis.vue')},
  {path: '/relatorio/recebiveis/visualizacao',name: 'RelRecebiveis',component: () => import('@/views/negocios/relatorios/RelRecebiveis.vue')},
  {path: '/relatorio/unidade',name: 'relatorioUnidade',component: () => import('@/views/negocios/relatorios/CfgUnidade.vue')},
  {path: '/relatorio/unidade/visualizacao',name: 'relUnidade',component: () => import('@/views/negocios/relatorios/RelUnidade.vue')},
  {path: '/relatorio/iptu',name: 'relatorioIptu',component: () => import('@/views/negocios/relatorios/CfgIptu.vue')},
  {path: '/relatorio/iptuRelatorio/',name: 'relIptu',component: () => import('@/views/negocios/relatorios/RelIptu.vue')},
  {path: '/relatorio/fundoReserva',name: 'relatorioFundoReserva',component: () => import('@/views/negocios/relatorios/CfgFundoReserva.vue')},
  {path: '/relatorio/fundoReservaRelatorio/',name: 'relFundoReserva',component: () => import('@/views/negocios/relatorios/RelFundoReserva.vue')},
  {path: '/relatorio/sped',name: 'relatorioSped',component: () => import('@/views/negocios/relatorios/CfgSped.vue')},
  {path: '/relatorio/spedRelatorio/',name: 'relSped',component: () => import('@/views/negocios/relatorios/RelSped.vue')},
  {path: '/relatorio/dimob',name: 'relatorioDimob',component: () => import('@/views/negocios/relatorios/CfgDimob.vue')},
  {path: '/relatorio/confrontante',name: 'relatorioConfrontante',component: () => import('@/views/negocios/relatorios/CfgConfrontante.vue')},
  {path: '/relatorio/confrontante/visualizacao',name: 'RelConfrontante',component: () => import('@/views/negocios/relatorios/RelConfrontante.vue')},
  {path: '/relatorio/reldimob/',name: 'relDimob',component: () => import('@/views/negocios/relatorios/RelDimob.vue')},
  {path: '/relatorio/pessoa/visualizacao',name: 'relPessoa',component: () => import('@/views/negocios/relatorios/RelPessoa.vue')},
    
  /*Relatorio de Classificacão */
  {path: '/financeiro/cfgClassificacao',name: 'cfgClassificacao',component: () => import('../views/financeiro/relatorios/CfgClassificacao.vue')},
  {path: '/relatorio/financeiro/relClassificacao',name: 'relClassificacao',component: () => import('../views/financeiro/relatorios/RelClassificacao.vue')},
  {path: '/financeiro/boleto',name: 'financeiroBoleto',component: () => import('@/views/financeiro/boleto/RelBoletoFinanceiro.vue')},

  /* COMPRAS */
  {path: '/compras/pedido', name: 'compraspedido',component: () => import('../views/compras/pedido/ListaPedido.vue')},
  {path: '/compras/orcamento',name: 'comprasorcamento',component: () => import('../views/compras/orcamento/ListaOrcamento.vue')}, 
  {path: '/compras/parametrizar',name: 'comprasparametrizar',component: () => import('../views/compras/parametrizar/ListaParametrizar.vue')},
  {path: '/compras/relatorio/pedido/configuracao',name: 'cfgPedidoCompra',component: () => import('../views/compras/relatorio/CfgPedido.vue')},
  {path: '/compras/relatorio/pedido/visualizacao',name: 'relPedidoCompra',component: () => import('../views/compras/relatorio/RelPedido.vue')},
 
  /* ALMOXARIFADO */
  {path: '/almoxarifado/almoxarifado',name: 'almoxarifadoLista',component: () => import('../views/almoxarifado/almoxarifado/ListaAlmoxarifado.vue')},
  {path: '/almoxarifado/unidademedida',name: 'almoxarifadoUnidadeMedida',component: () => import('../views/almoxarifado/unidadeMedida/ListaUnidadeMedida.vue')},
  {path: '/almoxarifado/grupoproduto',name: 'almoxarifadoGrupoProduto',component: () => import('../views/almoxarifado/grupo/ListaGrupoProduto.vue')},
  {path: '/almoxarifado/produto',name: 'almoxarifadoProduto',component: () => import('../views/almoxarifado/produto/ListaProduto.vue')},
  {path: '/almoxarifado/movimentacao',name: 'almoxarifadoMovimentacao',component: () => import('../views/almoxarifado/movimentacao/ListaMovimentacao.vue')},
  {path: '/almoxarifado/requisicao',name: 'almoxarifadoRequisicao',component: () => import('../views/almoxarifado/requisicao/ListaRequisicao.vue')},
  {path: '/almoxarifado/inventario',name: 'almoxarifadoInventario',component: () => import('../views/almoxarifado/inventario/ListaInventario.vue')},
  {path: '/almoxarifado/relatorio/requisicao/configuracao',name: 'cfgRequisicao',component: () => import('../views/almoxarifado/relatorios/CfgRequisicao.vue')},
  {path: '/almoxarifado/relatorio/requisicao/visualizacao',name: 'relRequisicao',component: () => import('../views/almoxarifado/relatorios/RelRequisicao.vue')},
  {path: '/almoxarifado/relatorio/movimentacao/configuracao',name: 'cfgMovimentacao',component: () => import('../views/almoxarifado/relatorios/CfgMovimentacao.vue')},
  {path: '/almoxarifado/relatorio/movimentacao/visualizacao',name: 'relMovimentacao',component: () => import('../views/almoxarifado/relatorios/RelMovimentacao.vue')},
  {path: '/almoxarifado/relatorio/estoque/configuracao',name: 'cfgEstoque',component: () => import('../views/almoxarifado/relatorios/CfgEstoque.vue')}, 
  {path: '/almoxarifado/relatorio/estoque/visualizacao',name: 'relEstoque',component: () => import('../views/almoxarifado/relatorios/RelEstoque.vue')},

  /* FINANCEIRO */
  {path: '/financeiro/banco',name: 'financeiroBanco',component: () => import('@/views/financeiro/banco/ListaBanco.vue')},
  {path: '/cadastro/indice', name: 'cadastroIndice',component: () => import('@/views/vendas/indice/ListaIndice.vue')}, 
  {path: '/financeiro/contacorrente',name: 'financeirocontacorrente',component: () => import('../views/financeiro/contaCorrente/ListaContaCorrente.vue')},
  {path: '/financeiro/centrocusto',name: 'financeiroCentroCusto',component: () => import('../views/financeiro/centroCusto/ListaCentroCusto.vue')},
  {path: '/financeiro/contagerencial',name: 'financeiroContaGerencial',component: () => import('../views/financeiro/contaGerencial/ListaContaGerencial.vue')},
  {path: '/financeiro/formapagamento',name: 'financeiroformapagamento',component: () => import('../views/financeiro/formaPagamento/ListaFormaPagamento.vue')},
  {path: '/financeiro/tiporeceita',name: 'financeirotiporeceita',component: () => import('../views/financeiro/tipoReceita/ListaTipoReceita.vue')},
  {path: '/financeiro/remessa',name: 'financeiroRemessa',component: () => import('@/views/financeiro/remessa/ListaRemessa.vue')},
  {path: '/financeiro/despesa',name: 'financeirodespesa',component: () => import('../views/financeiro/despesa/ListaDespesa.vue')},
  {path: '/financeiro/receita',name: 'financeiroReceita', component: () => import('../views/financeiro/receita/ListaReceita.vue')},
  {path: '/cadastro/tipodocumento',name: 'cadastroTipoDocumento', component: () => import('../views/financeiro/tipoDocumento/ListaTipoDocumento.vue')}, 
  {path: '/financeiro/relatorio/despesaDetalhada/configuracao',name: 'cfgDespesaDetalhada', component: () => import('../views/financeiro/relatorios/CfgDespesa.vue')},
  {path: '/financeiro/relatorio/despesaDetalhada/visualizacao',name: 'relDespesaDetalhada',component: () => import('../views/financeiro/relatorios/RelDespesa.vue')},  
  {path: '/financeiro/ofx/configuracao',name: 'cfgOfx',component: () => import('../views/financeiro/ofx/CfgOfx.vue')},  
  {path: '/financeiro/relatorio/despesaRateada/configuracao',name: 'cfgDespesaRateada', component: () => import('../views/financeiro/relatorios/CfgDespesaRateada.vue')},
  {path: '/financeiro/relatorio/despesaRateada/visualizacao',name: 'relDespesaRateada',component: () => import('../views/financeiro/relatorios/RelDespesaRateada.vue')},  
  {path: '/financeiro/relatorio/fluxocaixa/configuracao',name: 'cfgFluxoCaixa', component: () => import('../views/financeiro/relatorios/CfgFluxoCaixa.vue')},
  {path: '/financeiro/relatorio/fluxocaixa/visualizacao',name: 'relFluxoCaixa',component: () => import('../views/financeiro/relatorios/RelFluxoCaixa.vue')},  
 
   
  /* Novo layout */
  {path: '/financeiro/painel/vendas',name: 'financeiropainelvendas',component: () => import('../views/painel/vendas.vue')},
  {path: '/financeiro/painel/vgv',name: 'financeiropainelvgv',component: () => import('../views/painel/vgv.vue')},
  {path: '/financeiro/painel/cobranca',name: 'financeiropainelcobranca',component: () => import('../views/painel/cobranca.vue')},
  {path: '/financeiro/painel/despesa',name: 'financeiropaineldespesa',component: () => import('../views/painel/despesa.vue')},
  {path: '/financeiro/painel/receita',name: 'financeiropainelreceita',component: () => import('../views/painel/receita.vue')},
  {path: '/financeiro/painel/kpi',name: 'financeiropainelkpi',component: () => import('../views/painel/kpi.vue')},
  {path: '/financeiro/painel/fluxocaixa',name: 'financeiropainelfluxocaixa',component: () => import('../views/painel/fluxoCaixa.vue')},

  /* VENDAS */
  {path: '/financeiro/planopagamento',name: 'financeiroPlanoPagamento',component: () => import('@/views/vendas/planopagamento/ListaPlanoPagamento.vue')}, 
  {path: '/finaceiro/parametrizar',name: 'financeiroParametrizar',component: () => import('../views/administracao/parametrizacao/ListaParametrizar.vue')}, 
  {path: '/Comprovante',name: 'RelComprovante',component: () => import('../views/vendas/relatorios/RelSimulacaoAntecipacao.vue')}, 
  {path: '/SimulacaoQuitacao',name: 'RelSimulacaoQuitacao',component: () => import('../views/vendas/relatorios/RelSimulacaoQuitacao.vue')}, 
  {path: '/clientes/relatoriopricesac/receita/configuracao',name: 'CfgPriceSac',component: () => import('../views/vendas/relatorios/CfgPriceSac.vue')},
  {path: '/clientes/relatoriopricesac/visualizacao',name: 'relPriceSac',component: () => import('../views/vendas/relatorios/RelPriceSac.vue')},
  {path: '/clientes/relatorio/receita/configuracao',name: 'CfgReceitaContrato',component: () => import('../views/vendas/relatorios/CfgReceita.vue')},
  {path: '/clientes/relatorio/visualizacao',name: 'relReceitaContrato',component: () => import('../views/vendas/relatorios/RelParcelaDetalhada.vue')},
  {path: '/clientes/relatorio/boleto/configuracao',name: 'CfgBoleto',component: () => import('../views/vendas/relatorios/CfgBoleto.vue')}, 
  {path: '/clientes/relatorio/boleto/visualizacao',name: 'RelBoleto',component: () => import('../views/vendas/relatorios/RelBoleto.vue')},
  {path: '/clientes/relatorio/boletos/safra',name: 'RelBoletoSafra',component: () => import('../views/vendas/relatorios/RelBoletoSafra.vue')},
  {path: '/clientes/relatorio/taxaboleto/configuracao',name: 'CfgTaxaBoleto',component: () => import('../views/vendas/relatorios/CfgTaxaBoleto.vue')},
  {path: '/clientes/relatorio/taxaboleto/visualizacao',name: 'RelTaxaBoleto',component: () => import('../views/vendas/relatorios/RelTaxaBoleto.vue')}, 
  {path: '/clientes/relatorio/receita/visualizacao',name: 'RelRelatorioReceita',component: () => import('../views/vendas/relatorios/RelRelatorioReceita.vue')}, 
  {path: '/clientes/relatorio/contratoAtraso/configuracao',name: 'CfgContratoAtraso',component: () => import('../views/vendas/relatorios/CfgContratoAtraso.vue')},
  {path: '/clientes/relatorio/contratoAtraso/visualizacao',name: 'RelContratoAtraso',component: () => import('../views/vendas/relatorios/RelContratoAtraso.vue')}, 
  {path: '/clientes/relatorio/contratoSituacao/configuracao',name: 'CfgContratoSituacao',component: () => import('../views/vendas/relatorios/CfgContratoSituacao.vue')},
  {path: '/clientes/relatorio/contratoAditado/configuracao',name: 'CfgContratoAditado',component: () => import('../views/vendas/relatorios/CfgContratoAditado.vue')},
  {path: '/clientes/relatorio/contratoAditado/visualizacao',name: 'RelContratoAditado',component: () => import('../views/vendas/relatorios/RelContratoAditado.vue')},
  {path: '/clientes/relatorio/parcelaAditada/configuracao',name: 'CfgParcelaAditada',component: () => import('../views/vendas/relatorios/CfgParcelaAditada.vue')},
  {path: '/clientes/relatorio/parcelaAditada/visualizacao',name: 'RelParcelaAditada',component: () => import('../views/vendas/relatorios/RelParcelaAditada.vue')}, 
  {path: '/clientes/relatorio/contratoSituacao/visualizacao',name: 'RelContratoSituacao',component: () => import('../views/vendas/relatorios/RelContratoSituacao.vue')},
  {path: '/clientes/relatorio/vendas/configuracao',name: 'CfgVendas',component: () => import('../views/vendas/relatorios/CfgVendas.vue')},
  {path: '/clientes/relatorio/vendas/visualizacao',name: 'RelVendas',component: () => import('../views/vendas/relatorios/RelVendas.vue')},
  {path: '/contratos/Modelos',name: 'contratosModelos',component: () => import('@/views/vendas/modelos/ListaModelo.vue')},
  {path: '/gestao/contrato',name: 'cadastroContrato',component: () => import('@/views/vendas/contratos/ListaContrato.vue')},
  {path: '/cadastro/tipoProcessoJudicial',name: 'cadastroTipoProcessoJudicial',component: () => import('@/views/vendas/processo/ListaTipoProcessoJudicial.vue')},  
  {path: '/cadastro/tipoContrato',name: 'cadastroTipoContrato',component: () => import('@/views/vendas/tipocontrato/ListaTipoContrato.vue')},
  {path: '/clientes/relatorio/smsTotalEnviado/configuracao',name: 'CfgSmsTotalEnviado',component: () => import('@/views/vendas/relatorios/CfgSmsTotalEnviado.vue')},
  {path: '/relatorio/RelSmsTotalEnviado',name: 'RelSmsTotalEnviado',component: () => import('@/views/vendas/relatorios/RelSmsTotalEnviado.vue')},
  {path: '/clientes/relatorio/contratosDigitaisTotalEnviado/configuracao',name: 'RelatorioContratosDigitaisTotalEnviado',component: () => import('@/views/vendas/relatorios/CfgContratosDigitaisTotalEnviado.vue')},
  {path: '/relatorio/contratosDigitaisTotalEnviado',name: 'RelContratosDigitaisTotalEnviado',component: () => import('@/views/vendas/relatorios/RelContratosDigitaisTotalEnviado.vue')},
  {path: '/listar/regua',name: 'ListarRegua',component: () => import('@/views/vendas/regua/ListarRegua.vue')}, 
  
  /* ORÇAMENTO */   
  { path: '/cronograma/gantt',name: 'CronogramaGantt',component: () => import('../views/obras/cronograma/Gantt.vue')},
  { path: '/orcamento/tipocomposicao',name: 'OrcamentoTipoComposicao',component: () => import('../views/obras/tipocomposicao/ListaTipoComposicao.vue')},
  { path: '/orcamento/classecomposicao',name: 'OrcamentoClasseComposicao',component: () => import('../views/obras/classecomposicao/ListaClasseComposicao.vue')},
  { path: '/orcamento/composicao', name: 'OrcamentoComposicao', component: () => import('../views/obras/composicao/ListaComposicao.vue')},
  { path: '/orcamento/insumo', name: 'OrcamentoInsumo', component: () => import('../views/obras/insumo/ListaInsumo.vue')},
  { path: '/orcamento/orcamentoobras', name: 'OrcamentoOrcamentoObras', component: () => import('../views/obras/orcamento/ListaOrcamento.vue')},
  { path: '/gestao/etapa',name: 'GestaoEtapa',component: () => import('../views/obras/modeloetapa/ListaEtapa.vue')}, 

  /* GESTÃO DE PROJETOS */   
  { path: '/gestao/contratos',name: 'GestaoContratos',component: () => import('../views/prestacaoservico/contrato/ListaContrato.vue')},
 
  /* ÁGUA */ 
  { path: '/agua/consumo', name: 'AguaConsumo', component: () => import('../views/agua/consumo/ListaTabelaValorConsumoAgua.vue')},
  { path: '/agua/hidrometro', name: 'AguaHidrometro', component: () => import('../views/agua/hidrometro/ListaHidrometro.vue')},
  { path: '/agua/marcacao', name: 'AguaMarcacao', component: () => import('../views/agua/marcacao/ListaMarcacao.vue')},
  { path: '/agua/qualidade', name: 'AguaQualidade', component: () => import('../views/agua/qualidadeagua/ListaQualidadeAgua.vue')},
    
  /*ADM */
  { path: '/administracao/permissao', name: 'administracaoPermissao', component: () => import('../views/administracao/permissionamento/ListaPermissao.vue')},
  { path: '/cadastro/assinante', name: 'cadastroAssinante',component: () => import('@/views/administracao/base/ListaAssinante.vue')},
  { path: '/cadastro/usuario',name: 'cadastroUsuario',component: () => import('@/views/administracao/usuario/ListaUsuario.vue')},
  { path: '/administracao/parcela',name: 'InfoParcela',component: () => import('@/views/administracao/parcela/ListaParcela.vue')},
  { path: '/lista/cofre', name: 'listaCofre', component: () => import('@/views/administracao/cofres/ListaCofre.vue')},
  { path: "/login/resetSenha/:token", name: "ResetarSenha", component: () => import("../views/ResetarSenha.vue")},
  { path: "/login/:token",name: "ConfirmaEmail",component: () => import("../views/Login.vue")},
] 

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router 